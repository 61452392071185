import { motion } from "framer-motion";
import { useMemo, memo } from 'react';
import arrow from '../../../utils/images/arrow_right.svg';
import completed from '../../../utils/images/completed.svg';
import useUserStore from '../../../utils/stores/useUserStore';
import LoadingComponent from '../../../utils/LoadingComponent';
import {calculateCurrentPeriod} from '../../../modules/QuizModule/utils/UtilityFunctions';
import '../EmployeeModule.css';
const StatusIcon = ({ isCompleted }) => (
  <img 
    className='employee-module__item__success' 
    src={isCompleted ? completed : undefined} 
    alt={isCompleted ? "Completed icon" : undefined} 
  />
);

const EmployeeQuizItem = memo(({ item, isYours }) => {
  const { settings } = useUserStore();
  
  const periodObject = useMemo(() => calculateCurrentPeriod(item, settings), [item, settings]);
  const [isQuizFilledByStaff, isQuizFilledByInspector] = useMemo(() => [
    item.answer_on_question_for_staff.some(i => i.answer === null),
    item.answer_on_question_for_inspector.some(i => i.answer === null)
  ], [item]);

  const getStatusContent = useMemo(() => {
    const baseUrl = `/quiz/${item.id}`;
    const statusMap = {
      'Оценен руководителем': <a href={baseUrl} className='employee-module__item__status'>Завершено</a>,
      'Оценен сотрудником': <a className='employee-module__item__button' href={baseUrl}>Оценить</a>,
      'Создана': <a className='employee-module__item__status' href={baseUrl}>Ожидает оценки сотрудника</a>,
      'Завершен': <a href={baseUrl} className='employee-module__item__status'>Завершено</a>,
    };
    
    return isYours && periodObject.currentPeriod === 'inspector'
      ? <a className='employee-module__item__button' href={baseUrl}>Оценить</a>
      : statusMap[item.status_doc_quiz_name];
  }, [item, isYours, periodObject]);

  return (
    <motion.div
      className='employee-module__item'
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 0.2, type: 'tween' } }
      }}
    >
      <div className='employee-module__item__name-block'>
        <p className='employee-module__item__name'>{item.full_name}</p>
        <p className='employee-module__item__post'>{item.staff_position}</p>
      </div>

      <StatusIcon isCompleted={!isQuizFilledByStaff} />
      <StatusIcon isCompleted={!isQuizFilledByInspector} />

      {getStatusContent}

      <a className='employee-module__item__link' href={`/profile/${item.staff_id}`}>
        <p>История</p>
        <img alt='Перейти в профиль' className='employee-module__arrow' src={arrow} />
      </a>
    </motion.div>
  );
});

const EmployeesQuizesList = ({ quizList = [], isYours, isLoading }) => {
  return (
    <motion.div className="quiz-list">
      {isLoading ? (
        <LoadingComponent />
      ) : quizList?.length ? (
        quizList.map((item) => (
          <EmployeeQuizItem key={item.id} item={item} isYours={isYours} />
        ))
      ) : (
        <div className="empty-message">Пока тут пусто</div>
      )}
    </motion.div>
  );
};

export default EmployeesQuizesList;