import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import loading from '../../utils/images/loading.gif';
import api from "../../utils/Api";
import './QuizModule.css';
import QuizStatusBar from "../../components/QuizStatusBar/QuizStatusBar";
import QuizBody from "../../components/QuizBody/QuizBody";
import { useNavigate, useParams } from "react-router-dom";
import arrow_left from '../../utils/images/arrow_left.svg';
import LoadingComponent from '../../utils/LoadingComponent';
import SuccessQuizPopup from "./Popups/SuccessQuizPopup";
import BeforeExitPopup from "./Popups/BeforeExitPopup";
import ChangeRespondent from "./Popups/ChangeRespondent";
import InspectorQuizModule from "../InspectorQuizModule/InspectorQuizModule";
import useQuizStore from "../../utils/stores/useQuizStore";
import CalibrateQuizModule from "../CalibrateQuizModule/CalibrateQuizModule";
import ErrorComponent from "../../utils/ErrorComponent";
import useUserStore from "../../utils/stores/useUserStore";
import { calculateCompletion, calculateCurrentPeriod } from "./utils/UtilityFunctions";
import { getToken } from "../../utils/cookieHandlers";
import ManagerAnalyticsModule from "../ManagerAnalyticsModule/ManagerAnalyticsModule";
function QuizModule({ currentUser, userProfile }) {
    const { quizId } = useParams();
    const {
        isYours,
        isInspector,
        setQuiz,
        quiz,
        isCalibrator,
        isDirty,
        currentPeriod,
        setCurrentPeriod,
        setDeadlineDateInspector,
        setDeadlineDateStaff,
        setDeadlineDateCalibration,
    } = useQuizStore();
    const { settings, hasBURole, hasHRRole } = useUserStore();
    const hasBuRole = hasHRRole();
    const hasHrRole = hasHRRole();
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setSuccess] = useState(false);
    const [isError, setError] = useState(null);

    const [isRespondentOpened, setRespondentOpened] = useState(false);
    const [isPopupOpened, setPopupOpened] = useState(false);

    const [completionState, setCompletionState] = useState({
        totalQuestions: 0,
        filledAnswers: 0,
        completionPercentage: 0,
    });

    useEffect(() => {
        if (isYours && !isInspector) {
            if (quiz?.status_doc_quiz_name === 'Создана' && currentPeriod === 'staff') {
                const completion = calculateCompletion(quiz.answer_on_question_for_staff);
                setCompletionState(completion);
            }
        }

        if (isInspector && !isYours) {
            const completion = calculateCompletion(quiz.answer_on_question_for_inspector);
            setCompletionState(completion);
        }
    }, [quiz]);

    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const calibrate = queryParams.get("calibrate");
    const fromSearch = queryParams.get("fromSearch");
    useEffect(() => {
        document.title = "Pridex Plus | Анкета";
    }, []);


    useEffect(() => {
        const { currentPeriod, deadlineStaffDate, deadlineInspectorDate, deadlineCalibrationDate } = calculateCurrentPeriod(quiz, settings);
        setCurrentPeriod(currentPeriod);
        setDeadlineDateInspector(deadlineInspectorDate);
        setDeadlineDateStaff(deadlineStaffDate);
        setDeadlineDateCalibration(deadlineCalibrationDate);
    }, [quiz])


    const getRole = (isInspector, isYours, isCalibrator) => {
        switch (true) {
            case isCalibrator:
                return 'calibrate';
            case isInspector:
                return 'inspector';
            case isYours:
                return 'yours';
            default:
                return null;
        }
    }

    const getQuizById = async () => {
        try {
            setIsLoading(true);
            const token = getToken();
            const res = await api.getQuizById(token, quizId);
            setQuiz(res);
            setError(null);
        } catch (error) {
            console.log(error);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isDirty) {
            try {
                setIsLoading(true);
                const token = getToken();
                const role = getRole(isInspector, isYours, isCalibrator);
                const res = await api.patchFilledQuiz(token, quiz.id, quiz, role);
                setSuccess(true);
                setError(null);
            } catch (error) {
                console.log(error);
                setError(error);
            } finally {
                setIsLoading(false);
                window.scrollTo(0, 0);
            }
        } else return;
    }


    useEffect(() => {
        getQuizById();
    }, [quizId]);

    const renderQuizComponent = () => {
        if (calibrate === 'true' && isCalibrator) {
            return <CalibrateQuizModule setIsLoading={setIsLoading} setSuccess={setSuccess} setError={setError} />;
        }

        if (isYours && !isInspector) {
            if (quiz?.status_doc_quiz_name === 'Создана' && currentPeriod === 'staff') {
                return (
                    <div className="wrapper">
                        <div className="progress-bar">
                            <div className="progress-bar__fill" style={{ width: `${completionState.completionPercentage}%` }}></div>
                            <p className="completion-status">
                                {completionState.filledAnswers} из {completionState.totalQuestions} вопросов заполнено
                            </p>
                        </div>
                        <QuizBody />
                    </div>
                );
            }
            if (quiz?.status_doc_quiz_name === 'Оценен руководителем') {
                return (
                    <>
                        <InspectorQuizModule />
                    </>
                );
            }
        }

        if (isInspector && !isYours) {
            return (
                <div className="wrapper">
                    <div className="progress-bar">
                        <div className="progress-bar__fill" style={{ width: `${completionState.completionPercentage}%` }}></div>
                        <p className="completion-status">
                            {completionState.filledAnswers} из {completionState.totalQuestions} вопросов заполнено
                        </p>
                    </div>
                    <InspectorQuizModule />
                </div>
            );
        }

        return <InspectorQuizModule />;
    };

    if (isError) return <ErrorComponent isError={isError} />

    if (isLoading) {
        return <LoadingComponent />
    }

    return (
        <>
            <motion.div
                className="quiz-module__div"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                <button className="quiz-module__button-div"
                    type="button"
                    onClick={() => {
                        if (isDirty) {
                            setPopupOpened(true);
                        } else {
                            if (fromSearch) {
                                navigate('/employee-search');
                            } else {
                                navigate('/profile');
                            }

                        }
                    }}>
                    <img className="quiz-module__button" src={arrow_left} alt="arrow left" />
                    <p>Назад</p>
                </button>
                <form className="quiz-module__form-block" onSubmit={handleSubmit}>
                    <>
                        <QuizStatusBar setRespondentOpened={setRespondentOpened} />
                        {(isInspector || hasBURole() || hasHRRole()) && <ManagerAnalyticsModule />}
                        {renderQuizComponent()}
                        {isDirty &&
                            (isInspector || isYours) ?
                            <button className="quiz-body__button" type="submit">{completionState.completionPercentage === 100 ? 'Завершить' : 'Сохранить'}</button>
                            : null}
                    </>
                </form>

            </motion.div>
            <SuccessQuizPopup setSuccess={setSuccess} isSuccess={isSuccess} completionState={completionState} />
            <BeforeExitPopup isPopupOpened={isPopupOpened} setPopupOpened={setPopupOpened} />
            <ChangeRespondent isRespondentOpened={isRespondentOpened} setRespondentOpened={setRespondentOpened} quizId={quizId} getQuizById={getQuizById} />
        </>
    );
}

export default QuizModule;