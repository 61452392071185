import { useEffect, useMemo, useState } from 'react';
import './ScoreModule.css';
import api from '../../utils/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import arrow_blue from '../../utils/images/arrow_down_blue.svg';
import LoadingComponent from '../../utils/LoadingComponent';
import { convertDateToQuarter, formatDate, getDeadlineDateString } from '../../utils/TimeConvertFunctions';
import useUserStore from '../../utils/stores/useUserStore';
import { getToken } from '../../utils/cookieHandlers';
import { getUniqueYearsOptions } from './helpers/getYearsList';
import { ScoreModuleSelect } from './components/ScoreModuleSelect.js/ScoreModuleSelect';

export const API_PAGE_SIZE = 50; // Количество элементов, возвращаемых API на одной странице
export const RENDER_PAGE_SIZE = 4; // Количество элементов, отображаемых на одной странице

function ScoreModule({ userProfile }) {
    const [scoresList, setScoresList] = useState([]);
    const [filteredScoresList, setFilteredScoresList] = useState([]);
    const [currentApiPage, setCurrentApiPage] = useState(1);
    const [currentRenderPage, setCurrentRenderPage] = useState(1);
    const [currentOffset, setCurrentOffset] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [selectedYear, setSelectedYear] = useState(null);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { setUserQuizList } = useUserStore();

    const yearOptions = useMemo(() => getUniqueYearsOptions(scoresList), [scoresList]);

    // Определяем дефолтное значение года (самый свежий год, кроме "Показать все")
    const defaultYear = useMemo(() => yearOptions[1] || yearOptions[0] || null, [yearOptions]);

    // Загружаем список оценок пользователя
    useEffect(() => {
        if (userProfile?.id) {
            const fetchScoresList = async () => {
                setIsLoading(true);
                try {
                    const token = getToken();
                    const params = {
                        staff_id: userProfile.id,
                        page: currentApiPage,
                    };
                    const res = await api.fetchScores(token, params);
                    // Добавляем новые данные в существующий список
                    setScoresList(prevScores => [...prevScores, ...res.results]);
                    setTotalCount(res.count);
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchScoresList();
        }
    }, [userProfile, currentApiPage]);

    useEffect(() => {
        if (!selectedYear && defaultYear) {
            setSelectedYear(defaultYear);
        }
    }, [defaultYear]);

    // Фильтрация списка по выбранному году
    useEffect(() => {
        if (selectedYear?.value && selectedYear.value !== "all") {
            setFilteredScoresList(
                scoresList.filter(quiz => new Date(quiz.quarter).getFullYear() === parseInt(selectedYear.value))
            );
        } else {
            setFilteredScoresList(scoresList);
        }
        setCurrentRenderPage(1); // Сбрасываем страницу рендера при изменении фильтра
        setCurrentOffset(0); // Сбрасываем смещение
    }, [selectedYear, scoresList]);

    // Определяем мобильное разрешение экрана
    useEffect(() => {
        const updateIsMobile = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener("resize", updateIsMobile);
        return () => window.removeEventListener("resize", updateIsMobile);
    }, []);

    // Получаем буквенное обозначение оценки
    const getGradeSymbol = (score) => {
        return { 5.0: "A", 4.0: "B", 3.0: "C", 1.5: "D", 0.0: "E" }[score] || "?";
    };

    // Получаем итоговую оценку
    const getRequiredScore = (quiz) => {
        return quiz.answer_on_question_for_calibration
            ?.filter(item => item.category_name === "Итоговая оценка")
            ?.sort((a, b) => {
                const priority = { "Личная результативность": 1, "Оценка по компетенциям": 2 };
                return (priority[a.question_name] || Infinity) - (priority[b.question_name] || Infinity);
            })
            ?.map((item, index) => (
                <div key={index} className="score-module__score-item">
                    {getGradeSymbol(item.answer)}
                </div>
            ));
    };

    // Переход к викторине
    const goToQuiz = (quizId) => navigate(`/quiz/${quizId}`);

    // Отображаемые элементы
    const displayedItems = filteredScoresList.slice(currentOffset, currentOffset + RENDER_PAGE_SIZE);

    // Пагинация для рендера
    const totalRenderPages = Math.ceil(filteredScoresList.length / RENDER_PAGE_SIZE);
    const pageNumbers = Array.from({ length: totalRenderPages }, (_, i) => i + 1);

    // Обработчик изменения страницы
    const handlePageChange = (number) => {
        const newApiPage = Math.ceil((number * RENDER_PAGE_SIZE) / API_PAGE_SIZE);
        const newOffset = ((number - 1) * RENDER_PAGE_SIZE) % API_PAGE_SIZE;

        // Если данные для новой страницы API еще не загружены, запрашиваем их
        if (newApiPage > currentApiPage) {
            setCurrentApiPage(newApiPage);
        }

        setCurrentOffset(newOffset);
        setCurrentRenderPage(number);
    };

    if (isLoading) return <LoadingComponent />;

    return (
        filteredScoresList.some(quiz => quiz.status_doc_quiz_name === "Завершен") && (
            <div className="score-module__block">
                <div className="score-module__header">
                    <div className="score-module__header__col" style={{ width: "60%" }}>
                        <p style={{ width: "100%", textAlign: "left", fontSize: "15px" }}>История оценок</p>
                    </div>
                    <ScoreModuleSelect
                        defaultValue={defaultYear}
                        options={yearOptions}
                        onChange={setSelectedYear}
                        value={selectedYear}
                        img={arrow_blue}
                    />
                </div>
                <div className="score-module__subheader">
                    <div className="score-module__subheader__col">
                        <p>Период</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }} id="score-final-empl">
                        <div className="score-module__subheader__col" id="score-final-result">
                            <p>{isMobile ? "Результат" : "Личная результативность"}</p>
                        </div>
                        <div className="score-module__subheader__col" id="score-final-result">
                            <p>{isMobile ? "Ценности" : "Оценка по компетенциям"}</p>
                        </div>
                    </div>
                </div>
                {displayedItems
                    .filter(quiz => quiz.status_doc_quiz_name === "Завершен")
                    .map((quiz) => (
                        <div
                            className="score-module__item"
                            id={quiz.id}
                            key={quiz.id}
                            onClick={() => goToQuiz(quiz.id)}
                        >
                            <div className="score-module__history-block">{convertDateToQuarter(quiz.quarter)}</div>
                            <div className="score-module__final-block">{getRequiredScore(quiz)}</div>
                        </div>
                    ))}
                {pageNumbers.length > 1 && (
                    <div className="pagination-controls">
                        <div className="pagination">
                            {pageNumbers.map((number) => (
                                <div
                                    key={number}
                                    className={`page-item ${currentRenderPage === number ? "page-item_active" : ""}`}
                                    onClick={() => handlePageChange(number)}
                                >
                                    <button className="page-link">{number}</button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        )
    );
}

export default ScoreModule;