import { Outlet, Navigate } from 'react-router-dom'
import LoadingComponent from './LoadingComponent'
import ErrorComponent from './ErrorComponent'

const PrivateRoutes = ({
  isLoggedIn,
  loadingError,
  isLoading,
  allowedRoles,
  userRoles = []
}) => {
  if (isLoading) {
    return <LoadingComponent />
  }
  if (!isLoggedIn) {
    return <Navigate to="/sign-in" />
  }
  if (allowedRoles != null) {
    let rolesArray

    if (Array.isArray(allowedRoles)) {
      rolesArray = allowedRoles.filter((role) => role)
    } else {
      rolesArray = allowedRoles
        .split(',')
        .map((role) => role.trim())
        .filter((role) => role)
    }
    if (rolesArray.length > 0) {
      const userRoleNames = userRoles.map((role) => role.name)
      const hasAccess = rolesArray.some((requiredRole) =>
        userRoleNames.includes(requiredRole)
      )
      if (!hasAccess) {
        return <Navigate to="/sign-in" />
      }
    }
  }

  return <Outlet />
}

export default PrivateRoutes