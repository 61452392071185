import { useNavigate } from "react-router-dom";
import useUserStore from "../../utils/stores/useUserStore";
import { convertDateToQuarter, getDeadlineDateString } from "../../utils/TimeConvertFunctions";
import LoadingComponent from "../../utils/LoadingComponent";
import white_arrow from '../../utils/images/arrow_right_white.svg';
import { useEffect } from "react";

function ActualScoreModule({ profileId }) {
    const {
        userQuizList,
        isLoading,
        settings,
        userActualQuizList,
        fetchOtherUserActualScores,
        otherUserActualQuizList,
        fetchActualUserScores
    } = useUserStore();
    const navigate = useNavigate();
    const goToQuiz = (quizId) => navigate(`/quiz/${quizId}`);

    useEffect(() => {
        if (profileId) {
            fetchOtherUserActualScores(profileId);
        } else {
            fetchActualUserScores();
        }
    }, [profileId, fetchOtherUserActualScores, fetchActualUserScores]);

    const actualQuizzes = profileId ? otherUserActualQuizList : userActualQuizList;

    if (isLoading || !settings || !actualQuizzes) return <LoadingComponent />;



    return (
        <>
            {actualQuizzes?.length > 0 && (
                <div className='score-module__block'>
                    <div className='score-module__header'>
                        <div className='score-module__header__col' style={{ width: '60%' }}>
                            <p style={{ width: '100%', textAlign: 'left' }}>Актуальная анкета</p>
                        </div>
                    </div>

                    {actualQuizzes.map((quiz) => {
                        const { fullText, shortText } = getDeadlineDateString(
                            quiz.time_create,
                            settings?.find(item => item.key === 'days_for_staff')?.value
                        );

                        return (
                            <div className='score-module__row' key={quiz.id}>
                                <div className='score-module__status-block'>
                                    {convertDateToQuarter(quiz.quarter)}

                                    <div className='score-module__date-block'>
                                        {quiz.status_doc_quiz_name === 'Оценен сотрудником' && (
                                            <>
                                                <span className="full-text">Ожидание оценки руководителя</span>
                                                <span className="short-text">Ожид. оценки рук.</span>
                                            </>
                                        )}

                                        {quiz.status_doc_quiz_name === 'Создана' && (
                                            <>
                                                <span className="full-text">{fullText}</span>
                                                <span className="short-text">{shortText}</span>
                                            </>
                                        )}

                                        {quiz.status_doc_quiz_name === 'Оценен руководителем' && (
                                            <p style={{ width: '100%', textAlign: 'left' }}>
                                                Оценка завершена
                                            </p>
                                        )}
                                    </div>
                                </div>

                                <a className='score-module__button' href={`/quiz/${quiz.id}`}>
                                    <p>Перейти к анкете</p>
                                    <img src={white_arrow} alt="arrow" />
                                </a>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}

export default ActualScoreModule;