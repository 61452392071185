import React from 'react';
import { Chart } from 'react-charts';

const DualDistributionChart = ({ realData, idealData }) => {
  const safePercentage = (value, total) => {
    const numValue = Number(value) || 0;
    const numTotal = Number(total) || 1;
    return (numValue / numTotal) * 100;
  };

  const grades = ['A', 'B', 'C', 'D', 'E'];
  const performanceData = [
    {
      label: 'Фактическое',
      data: grades.map(grade => ({
        grade,
        value: safePercentage(realData?.personal_performance?.[`count_${grade}`], realData?.quiz_count_all)
      }))
    },
    {
      label: 'Идеальное',
      data: grades.map(grade => ({
        grade,
        value: Number(idealData?.[`${grade}_percentage`]) || 0
      }))
    }
  ];
  const assessmentTotal = Object.values(realData?.['competency assessment'] || {}).reduce((a, b) => a + (Number(b) || 0), 1);
  const assessmentData = [
    {
      label: 'Фактическое',
      data: grades.map(grade => ({
        grade,
        value: safePercentage(realData?.['competency assessment']?.[`count_${grade}`], assessmentTotal)
      }))
    },
    {
      label: 'Идеальное',
      data: grades.map(grade => ({
        grade,
        value: Number(idealData?.[`${grade}_percentage`]) || 0
      }))
    }
  ];

  const primaryAxis = {
    getValue: datum => datum.grade,
    scaleType: 'band'
  };

  const secondaryAxes = [
    {
      getValue: datum => datum.value,
      formatters: {
        scale: value => `${(value || 0).toFixed(1)}%`
      },
      min: 0,
      max: 100,
      elementType: "line"
    }
  ];

  const getSeriesStyle = React.useCallback((series) => ({
    line: {
      strokeWidth: 3,
      stroke: series.index === 0 ? '#002DFF' : '#ff9800',
      opacity: 1
    },
    point: {
      radius: 5,
      fill: series.index === 0 ? '#002DFF' : '#ff9800',
      stroke: 'white',
      strokeWidth: 2
    }
  }), []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      width: '100%',
      fontFamily: 'Arial, sans-serif',
    }}>
      <div style={{ height: '250px', width: '50%' }}>
        <Chart
          options={{
            data: performanceData,
            primaryAxis,
            secondaryAxes,
            series: {
              type: 'line',
              showPoints: true,
            },
            getSeriesStyle,
            dark: false,
            defaultColors: ['#002DFF', '#ff9800'],
          }}
        />
      </div>
      <div style={{ height: '250px', width: '50%' }}>
        <Chart
          options={{
            data: assessmentData,
            primaryAxis,
            secondaryAxes,
            series: {
              type: 'line',
              showPoints: true,
            },
            getSeriesStyle,
            dark: false,
            defaultColors: ['#002DFF', '#ff9800'],
          }}
        />
      </div>
    </div>
  );
};

export default DualDistributionChart;