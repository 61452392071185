import { create } from 'zustand';
import api from '../Api';
import { getToken, removeToken } from '../cookieHandlers';
import Cookies from 'js-cookie';

const useUserStore = create((set, get) => ({
    user: null,
    userProfile: null,
    isLoading: false,
    error: null,
    buId: null,
    userActualQuizList: null,
    otherUserActualQuizList: null,
    isLoggedIn: !!getToken(),

    logout: () => {
        console.log('Logout trigger');
        Object.keys(Cookies.get()).forEach(cookieName => {
            Cookies.remove(cookieName, { path: '/' });
        });
        localStorage.clear();
        set({
            user: null,
            userProfile: null,
            isLoggedIn: false,
            buId: null,
            userActualQuizList: null,
            otherUserActualQuizList: null,
            isLoading: false,
            error: null
        });
        document.location.reload();
    },

    setBuId: (buId) => set({ buId }),
    setUserQuizList: (userQuizList) => set({ userQuizList }),
    setIsLoading: (isLoading) => set({ isLoading }),
    setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn }),

    fetchUser: async () => {
        set({ isLoading: true, error: null });
        try {
            const token = getToken();
            const response = await api.fetchUser(token);
            set({ user: response, isLoading: false });
        } catch (error) {
            if (error.status === 403) {
                get().logout();
            }
            set({ error: error.message, isLoading: false, isLoggedIn: false });
        }
    },

    fetchProfile: async () => {
        const { user, fetchUser } = get();
        if (!user) await fetchUser();

        const { user: updatedUser } = get();
        if (updatedUser) {
            set({ isLoading: true, error: null });
            try {
                const token = getToken();
                const response = await api.fetchProfile(token, updatedUser.id);
                const profile = response.results[0];
                set({ userProfile: profile, isLoading: false, isLoggedIn: true });
                localStorage.setItem('currentProfile', JSON.stringify(profile));
                await get().fetchSettings();
                if (get().hasHRRole(profile)) {
                    await get().fetchBuId();
                }
            } catch (error) {
                if (error.status === 403) {
                    get().logout();
                }
                set({ error: error.message, isLoading: false, isLoggedIn: false });
            }
        }
    },

    fetchBuId: async () => {
        const { userProfile } = get();
        if (!userProfile) return;

        if (get().hasHRRole(userProfile) || get().hasBURole(userProfile)) {
            set({ isLoading: true, error: null });
            try {
                const token = getToken();
                const response = await api.getBuIdByHrId(token, userProfile.id);
                const buIds = response.results[0]?.items;
                set({ buId: buIds, isLoading: false });
            } catch (error) {
                if (error.status === 403) {
                    get().logout();
                }
                set({ error: error.message, isLoading: false });
            }
        }
    },

    fetchSettings: async () => {
        set({ isLoading: true, error: null });
        try {
            const token = getToken();
            const response = await api.getSettings(token);
            set({ settings: response, isLoading: false });
        } catch (error) {
            if (error.status === 403) {
                get().logout();
            }
            set({ error: error.message, isLoading: false });
        }
    },

    fetchActualUserScores: async () => {
        const { userProfile } = get();
        set({ isLoading: true, error: null });
        try {
            const token = getToken();
            const params = {
                staff_id: userProfile.id,
                status_doc_quiz__name: 'Оценен сотрудником, Создана, Оценен руководителем'
            };
            const res = await api.fetchScores(token, params);
            set({ userActualQuizList: res.results, isLoading: false });
        } catch (error) {
            if (error.status === 403) {
                get().logout();
            }
            set({ error: error.message, isLoading: false });
        }
    },

    fetchOtherUserActualScores: async (profileId) => {
        set({ isLoading: true, error: null });
        try {
            const token = getToken();
            const params = {
                staff_id: profileId,
                status_doc_quiz__name: 'Оценен сотрудником, Создана, Оценен руководителем'
            };
            const res = await api.fetchScores(token, params);
            set({ otherUserActualQuizList: res.results, isLoading: false });
        } catch (error) {
            if (error.status === 403) {
                get().logout();
            }
            set({ error: error.message, isLoading: false });
        }
    },

    hasHRRole: (profile) => {
        return profile?.roles?.some(role => role.name === 'HR');
    },

    hasBURole: (profile) => {
        return profile?.roles?.some(role => role.name === 'BU');
    },

    getCurrentProfile: () => {
        const currentProfile = JSON.parse(localStorage.getItem('currentProfile'));
        if (currentProfile) {
            set({ userProfile: currentProfile });
        }
        return currentProfile;
    },

    initializeStore: async () => {
        const token = getToken();
        if (token) {
            try {
                await get().fetchProfile();
                set({ isLoggedIn: true });
            } catch (error) {
                if (error.status === 403) {
                    get().logout();
                }
                set({ isLoggedIn: false });
            }
        } else {
            set({ isLoggedIn: false });
        }
    }
}));

useUserStore.getState().initializeStore();

export default useUserStore;