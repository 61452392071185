import { motion } from 'framer-motion';
import './MethodologyPage.css';
import { useNavigate } from 'react-router-dom';
import header_image from '../utils/images/header_methodology_image.svg';
import arrow_left from '../utils/images/arrow_left.svg';
function MethodologyPage() {

    const navigate = useNavigate();

    const handleGoBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/profile');
        }
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { duration: 0.5, type: 'tween' },
        },
    };

    const blockVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                type: "tween",
                duration: 0.3
            }
        },
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className='methodology-page__div'
        >
            <motion.div className='methodology-page__header'>
                <div className='methodology-page__header-wrapper'>
                <button onClick={() => handleGoBack()} className='methodology-page__back-button'><img src={arrow_left}></img>Назад</button>
                <div className='methodology-page__text-block'>
                    <h2>Оценка эффективности сотрудников&nbsp;Pridex Plus</h2>
                    <p>Со&nbsp;второго квартала 2024 года оценка эффективности сотрудников Pridex Plus перешла&nbsp;с&nbsp;1С&nbsp;на&nbsp;веб-сервис. Новый формат упростил процесс, сделав интерфейс интуитивным, а&nbsp;заполнение&nbsp;&mdash; быстрым и&nbsp;комфортным.</p>
                </div>
                </div>
            </motion.div>
            <motion.div className='methodology-page__main-block'>
                <motion.div className='methodology-page__content-block'>
                    <h2>Зачем мы оцениваем?</h2>
                    <p>Оценка эффективности — это важный инструмент, который помогает:</p>
                    <ul className='methodology-page__ul'>
                        <li className='methodology-page__li'>Повышать эффективность работы каждого сотрудника и компании в целом;</li>
                        <li className='methodology-page__li'>Формировать единые стандарты поведения и работы, основанные на ценностях и целях компании;</li>
                        <li className='methodology-page__li'>Укреплять диалог и взаимопонимание в команде;</li>
                        <li className='methodology-page__li'>Способствовать развитию и карьерному росту сотрудников;</li>
                        <li className='methodology-page__li'>Мотивировать на достижение плановых и сверхплановых результатов.</li>
                    </ul>
                </motion.div>
                <motion.div className='methodology-page__content-block' variants={blockVariants}>
                    <h2>Что мы оцениваем?</h2>
                    <div className='methodology-page__horizontal-block'>
                        <div className='methodology-page__list-block'>
                            <h2>Личная результативность</h2>
                            <p>Оцениваем, <span className='methodology-page__span'>что</span> мы делаем:</p>
                            <ul className='methodology-page__ul'>
                                <li className='methodology-page__li'>Цели: Достижение планов, завершение проектов в срок, рост показателей (продажи +10% за квартал).</li>
                                <li className='methodology-page__li'>Производительность: Эффективное время и ресурсы — оптимизация, новые инструменты, многозадачность.</li>
                                <li className='methodology-page__li'>Качество: Полное выполнение задач, без ошибок, по стандартам.</li>
                            </ul>
                        </div>
                        <div className='methodology-page__list-block'>
                            <h2>Ценностные компетенции</h2>
                            <p>Оцениваем, <span className='methodology-page__span'>как</span> мы делаем:</p>
                            <ul className='methodology-page__ul'>
                                <li className='methodology-page__li'>«Я лидер»: Ответственность, улучшение процессов, развитие (идеи, обучение).</li>
                                <li className='methodology-page__li'>«Мы команда»: Поддержка, доверие, обмен знаниями, позитив.</li>
                                <li className='methodology-page__li'>«Все для клиента»: Высокий сервис, решение жалоб, индивидуальный подход.</li>
                            </ul>
                        </div>
                    </div>
                </motion.div>
                <motion.div className='methodology-page__content-block' variants={blockVariants}>
                    <h2>Этапы оценки Pridex Plus</h2>
                    <div className='methodology-page__horizontal-block'>
                        <div className='methodology-page__card-block'>
                            <div className='methodology-page__card__text-block'>
                                <h2>Оценка сотрудниками</h2>
                                <p>Сотрудники заполняют самооценку по&nbsp;личной результативности и&nbsp;ценностным компетенциям. После этого руководители получают доступ к&nbsp;анкете и&nbsp;оценивают сотрудника по&nbsp;тем&nbsp;же критериям.</p>
                            </div>
                            <div className='methodology-page__card-row'>
                                <p>ПЕРВЫЙ</p>
                                <div className='methodology-page__duration-info'>2 НЕДЕЛИ</div>
                            </div>
                        </div>
                        <div className='methodology-page__card-block'>
                            <div className='methodology-page__card__text-block'>
                                <h2>Оценка руководителями</h2>
                                <p>Руководители проводят оценку сотрудников, учитывая установленные лимиты и&nbsp;критерии, а&nbsp;также результаты их&nbsp;деятельности за&nbsp;период.</p>
                            </div>
                            <div className='methodology-page__card-row'>
                                <p>ВТОРОЙ</p>
                                <div className='methodology-page__duration-info'>2 НЕДЕЛИ</div>
                            </div>
                        </div>
                        <div className='methodology-page__card-block'>
                            <div className='methodology-page__card__text-block'>
                                <h2>Калибровка</h2>
                                <p>Проводится калибровка оценок по&nbsp;подразделениям. Обсуждаются распределение оценок и&nbsp;результаты сотрудников с&nbsp;оценками A, D, E, с&nbsp;примерами их&nbsp;деятельности.</p>
                            </div>
                            <div className='methodology-page__card-row'>
                                <p>ТРЕТИЙ</p>
                                <div className='methodology-page__duration-info'>1 НЕДЕЛИ</div>
                            </div>
                        </div>
                    </div>
                    <p>По&nbsp;итогам оценки, каждый руководитель предоставляет сотрудникам обратную связь, сообщает результаты оценки, дает рекомендации и&nbsp;советы по&nbsp;повышению результативности и&nbsp;соответствия ценностям.</p>
                </motion.div>
                <motion.div className='methodology-page__content-block' variants={blockVariants}>
                    <h2>Как мы оцениваем?</h2>
                    <div className='methodology-page__score-card'>
                        <h2>Сверхдостижения</h2>
                        <p>Не более 10% сотрудников, демонстрирующих выдающиеся результаты</p>
                        <div className='methodology-page__score-card__list'>
                            <div className='methodology-page__score-card__list-item'>
                                <div className='methodology-page__score-card__list-score'>A</div>
                                <p>Значительно превосходит ожидания по всем показателям</p>
                            </div>
                            <div className='methodology-page__score-card__list-item'>
                                <div className='methodology-page__score-card__list-score'>B</div>
                                <p>Стабильно превосходит ожидания в работе</p>
                            </div>
                        </div>
                    </div>
                    <div className='methodology-page__score-card'>
                        <h2>Стабильность</h2>
                        <p>Успешные работники, полностью выполняющие свои обязанности без отклонений</p>
                        <div className='methodology-page__score-card__list'>
                            <div className='methodology-page__score-card__list-item'>
                                <div className='methodology-page__score-card__list-score'>C</div>
                                <p>Стабильно соответствует ожиданиям</p>
                            </div>
                        </div>
                    </div>
                    <div className='methodology-page__score-card'>
                        <h2>Недостаточность</h2>
                        <p>Работники, не приносящие пользы и снижающие эффективность</p>
                        <div className='methodology-page__score-card__list'>
                            <div className='methodology-page__score-card__list-item'>
                                <div className='methodology-page__score-card__list-score'>D</div>
                                <p>Требует улучшения в ключевых аспектах работы</p>
                            </div>
                            <div className='methodology-page__score-card__list-item'>
                                <div className='methodology-page__score-card__list-score'>E</div>
                                <p>Неудовлетворительно выполняет свои обязанности</p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

export default MethodologyPage;