import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { calculateCompletion } from '../../modules/QuizModule/utils/UtilityFunctions';
import { getCookie } from '../cookieHandlers';
import api from '../Api';

const useQuizStore = create(subscribeWithSelector((set, get) => ({
    quiz: null,
    groupedStaffQuiz: null,
    groupedInspectorQuiz: null,
    mergedInspectorQuiz: null,
    groupedCalibrateQuiz: null,
    calibrateFinalScores: null,
    mergedCalibrationQuiz: null,
    isYours: false,
    isInspector: false,
    isCalibrator: false,
    isDirty: false,
    isLoading: null,
    settings: null,
    currentPeriod: null,
    deadlineDateInspector: null,
    deadlineDateStaff: null,
    deadlineDateCalibration: null,
    quizAnalytics: null,

    setDeadlineDateCalibration: (deadlineDateCalibration) => set({ deadlineDateCalibration }),

    setDeadlineDateInspector: (deadlineDateInspector) => set({ deadlineDateInspector }),

    setDeadlineDateStaff: (deadlineDateStaff) => set({ deadlineDateStaff }),

    setCurrentPeriod: (period) => set({ currentPeriod: period }),

    setIsLoading: (isLoading) => set({ isLoading }),

    setQuiz: (quiz) => set({ quiz }),

    setDirty: (isDirty) => set({ isDirty }),

    getAnalyticsInfo: async () => {
        const { quiz, isInspector } = get();

        if (!quiz || !isInspector) {
            console.warn('Analytics info unavailable');
            return null;
        }

        try {
            set({ isLoading: true });
            const analyticsData = await api.getAnalytics({
                quarter: quiz.quarter,
                analytics_flag: 'letter',
                requested_flag: 'manager',
                nesting_company: false,
                nesting_bu: [],
                nesting_manager: [quiz.inspector_id]
            });

            set({
                quizAnalytics: analyticsData,
                isLoading: false
            });

            return analyticsData;
        } catch (error) {
            set({ isLoading: false });
            console.error('Failed to fetch analytics:', error);
            throw error;
        }
    },

    mergeInspectorAnswers: (employeeAnswers, managerAnswers) => {
        const allAnswers = [...employeeAnswers, ...managerAnswers];
        allAnswers.sort((a, b) => a.number_question - b.number_question);

        const groupedAnswers = {};

        allAnswers.forEach(answer => {
            const category = answer.category_name;
            const questionNumber = answer.number_question;
            if (!groupedAnswers[category]) {
                groupedAnswers[category] = {
                    category_name: category,
                    answers: {}
                };
            }
            if (!groupedAnswers[category].answers[questionNumber]) {
                groupedAnswers[category].answers[questionNumber] = {
                    staff: null,
                    inspector: null
                };
            }
            if (employeeAnswers.includes(answer)) {
                groupedAnswers[category].answers[questionNumber].staff = answer;
            } else {
                groupedAnswers[category].answers[questionNumber].inspector = answer;
            }
        });

        const mergedInspectorQuiz = Object.values(groupedAnswers).map(category => ({
            category_name: category.category_name,
            answers: Object.values(category.answers)
        }));

        set({ mergedInspectorQuiz });
        return mergedInspectorQuiz;
    },


    mergeCalibrationAnswers: (inspectorAnswers, calibrationAnswers) => {
        const groupedAnswers = {};

        inspectorAnswers.forEach(answer => {
            if (answer.category_name === 'Итоговая оценка') {
                const questionName = answer.question_name;

                if (!groupedAnswers[questionName]) {
                    groupedAnswers[questionName] = {
                        inspector: null,
                        calibrator: null
                    };
                }

                groupedAnswers[questionName].inspector = answer;
            }
        });

        calibrationAnswers.forEach(answer => {
            if (answer.category_name === 'Итоговая оценка') {
                const questionName = answer.question_name;

                if (!groupedAnswers[questionName]) {
                    groupedAnswers[questionName] = {
                        inspector: null,
                        calibrator: null
                    };
                }

                groupedAnswers[questionName].calibrator = answer;
            }
        });

        const mergedCalibrationQuiz = Object.values(groupedAnswers).map(question => ({
            question_name: question.inspector?.question_name || question.calibrator?.question_name,
            inspector: question.inspector,
            calibrator: question.calibrator
        }));

        set({ mergedCalibrationQuiz });
        return mergedCalibrationQuiz;
    },

    handleUpdateAnswer: (answerId, value, quizType) => {
        const { quiz } = get();

        if (!quiz) return;

        const updatedQuiz = { ...quiz };

        if (quizType.toLowerCase() === 'inspector' && quiz.answer_on_question_for_inspector) {
            const updatedAnswers = quiz.answer_on_question_for_inspector.map(answer => {
                if (answer.id_answer === answerId) {
                    return { ...answer, answer: value };
                }
                return answer;
            });
            updatedQuiz.answer_on_question_for_inspector = updatedAnswers;
        } else if (quizType.toLowerCase() === 'staff' && quiz.answer_on_question_for_staff) {
            const updatedAnswers = quiz.answer_on_question_for_staff.map(answer => {
                if (answer.id_answer === answerId) {
                    return { ...answer, answer: value };
                }
                return answer;
            });
            updatedQuiz.answer_on_question_for_staff = updatedAnswers;
        } else if (quizType.toLowerCase() === 'calibrate' && quiz.answer_on_question_for_calibration) {
            const updatedAnswers = quiz.answer_on_question_for_calibration.map(answer => {
                if (answer.id_answer === answerId) {
                    return { ...answer, answer: value };
                }
                return answer;
            });
            updatedQuiz.answer_on_question_for_calibration = updatedAnswers;
        }


        set({ quiz: updatedQuiz, isDirty: true });

        const groupedStaffQuiz = groupAndSortQuestions(updatedQuiz.answer_on_question_for_staff);
        set({ groupedStaffQuiz });
        calculateCompetencyScoresForStaff(set, get);
        calculateCompetencyScoresForInspector(set, get);
        calculateFinalScoreForStaff(set, get);
        calculateFinalScoreForInspector(set, get);
    },

    handleUpdateFullQuizComment: (value) => {
        const { quiz } = get();

        if (!quiz) return;

        const updatedQuiz = { ...quiz };

        updatedQuiz.comment = value;

        set({ quiz: updatedQuiz, isDirty: true });
    },

    handleUpdateComment: (questionId, value, quizType) => {
        const { quiz } = get();

        if (!quiz) return;

        const updatedQuiz = { ...quiz };

        if (quizType.toLowerCase() === 'inspector' && quiz.answer_on_question_for_inspector) {
            const updatedAnswers = quiz.answer_on_question_for_inspector.map(answer => {
                if (answer.question_id === questionId) {
                    return { ...answer, comment: value };
                }
                return answer;
            });
            updatedQuiz.answer_on_question_for_inspector = updatedAnswers;
        } else if (quizType.toLowerCase() === 'staff' && quiz.answer_on_question_for_staff) {
            const updatedAnswers = quiz.answer_on_question_for_staff.map(answer => {
                if (answer.question_id === questionId) {
                    return { ...answer, comment: value };
                }
                return answer;
            });
            updatedQuiz.answer_on_question_for_staff = updatedAnswers;
        } else if (quizType.toLowerCase() === 'calibrate' && quiz.answer_on_question_for_calibration) {
            const updatedAnswers = quiz.answer_on_question_for_calibration.map(answer => {
                if (answer.question_id === questionId) {
                    return { ...answer, comment: value };
                }
                return answer;
            });
            updatedQuiz.answer_on_question_for_calibration = updatedAnswers;
        }

        set({ quiz: updatedQuiz, isDirty: true });
    },

    sortStaffQuiz: () => {
        const { quiz } = get();
        if (!quiz || !quiz.answer_on_question_for_staff) return;

        const groupedStaffQuiz = groupAndSortQuestions(quiz.answer_on_question_for_staff);

        set({ groupedStaffQuiz });
        return groupedStaffQuiz;
    },

    sortInspectorQuiz: () => {
        const { quiz } = get();
        if (!quiz || !quiz.answer_on_question_for_inspector) return;

        const groupedInspectorQuiz = groupAndSortQuestions(quiz.answer_on_question_for_inspector);

        set({ groupedInspectorQuiz });
        return groupedInspectorQuiz;
    },

    sortCalibrateQuiz: () => {
        const { quiz } = get();
        if (!quiz || !quiz.answer_on_question_for_calibration) return;

        const groupedCalibrateQuiz = groupAndSortQuestions(quiz.answer_on_question_for_calibration);

        set({ groupedCalibrateQuiz });
        return groupedCalibrateQuiz;
    },

    calculateCurrentPeriod: () => {
        const { settings } = get();
        console.log(settings);
    },

})));

const groupAndSortQuestions = (staffAnswersList) => {
    const sortedQuestions = staffAnswersList.sort((a, b) => a.number_question - b.number_question);
    const groupedQuestions = {};
    sortedQuestions.forEach(item => {
        if (typeof item.answer === 'number') {
            item.answer = item.answer.toFixed(1);
        }

        if (!groupedQuestions[item.category_name]) {
            groupedQuestions[item.category_name] = {
                category_name: item.category_name,
                answers: [],
                averageScore: null
            };
        }
        groupedQuestions[item.category_name].answers.push(item);
    });
    Object.keys(groupedQuestions).forEach(category => {
        const totalScore = groupedQuestions[category].answers.reduce((acc, curr) => acc + parseFloat(curr.answer || 0), 0);
        const count = groupedQuestions[category].answers.filter(question => question.answer !== null).length;
        groupedQuestions[category].averageScore = count > 0 ? (totalScore / count).toFixed(1) : null;
    });

    return Object.values(groupedQuestions);
};

const calculateCompetencyScoresForStaff = (set, get) => {
    const { quiz } = get();
    if (!quiz || !quiz.answer_on_question_for_staff) return;

    const competencyCategories = ["Все - для клиента", "Мы - Команда", "Я - Лидер"];
    const competencyScores = {};

    competencyCategories.forEach(category => {
        const categoryAnswers = quiz.answer_on_question_for_staff.filter(answer => answer.category_name.toLowerCase() === category.toLowerCase() && answer.answer !== null);
        if (categoryAnswers.length > 0) {
            const totalScore = categoryAnswers.reduce((sum, answer) => sum + parseFloat(answer.answer), 0);
            const averageScore = totalScore / categoryAnswers.length;
            competencyScores[category] = getGradeInterval(averageScore);
        }
    });

    const updatedStaffAnswers = quiz.answer_on_question_for_staff.map(answer => {
        if (answer.category_name === "Оценка по компетенциям") {
            return { ...answer, answer: competencyScores[answer.question_name] };
        }
        return answer;
    });

    set((state) => ({
        quiz: {
            ...state.quiz,
            answer_on_question_for_staff: updatedStaffAnswers,
        },
    }));
};




const calculateCompetencyScoresForInspector = (set, get) => {
    const { quiz } = get();
    if (!quiz || !quiz.answer_on_question_for_inspector) return;

    const competencyCategories = ["Все - для клиента", "Мы - Команда", "Я - Лидер"];
    const competencyScores = {};

    competencyCategories.forEach(category => {
        const categoryAnswers = quiz.answer_on_question_for_inspector.filter(answer => answer.category_name.toLowerCase() === category.toLowerCase() && answer.answer !== null);
        if (categoryAnswers.length > 0) {
            const totalScore = categoryAnswers.reduce((sum, answer) => sum + parseFloat(answer.answer), 0);
            const averageScore = totalScore / categoryAnswers.length;
            competencyScores[category] = getGradeInterval(averageScore);
        }
    });

    const updatedInspectorAnswers = quiz.answer_on_question_for_inspector.map(answer => {
        if (answer.category_name === "Оценка по компетенциям") {
            return { ...answer, answer: competencyScores[answer.question_name] };
        }
        return answer;
    });

    set((state) => ({
        quiz: {
            ...state.quiz,
            answer_on_question_for_inspector: updatedInspectorAnswers,
        },
    }));

};



const calculateFinalScoreForStaff = (set, get) => {
    const { quiz } = get();
    if (!quiz || !quiz.answer_on_question_for_staff) return;

    const competencyScores = quiz.answer_on_question_for_staff.filter(answer => answer.category_name === "Оценка по компетенциям");

    const totalScoreCompetency = competencyScores.reduce((sum, answer) => sum + parseFloat(answer.answer), 0);
    const averageScoreCompetency = totalScoreCompetency / competencyScores.length;
    const personalEffectivenessScore = quiz.answer_on_question_for_staff.find(answer => answer.category_name === "Оценка личной результативности" && answer.question_name === "Личная результативность");

    const updatedFinalScoreForStaff = quiz.answer_on_question_for_staff.map(answer => {
        if (answer.category_name === "Итоговая оценка") {
            if (answer.question_name === "Оценка по компетенциям") {
                return { ...answer, answer: getGradeInterval(averageScoreCompetency.toFixed(1)) };
            } else if (answer.question_name === "Личная результативность") {
                return { ...answer, answer: personalEffectivenessScore.answer };
            }
        }
        return answer;
    });

    set((state) => ({
        quiz: {
            ...state.quiz,
            answer_on_question_for_staff: updatedFinalScoreForStaff,
        },
    }));
};


const calculateFinalScoreForInspector = (set, get) => {
    const { quiz } = get();
    if (!quiz || !quiz.answer_on_question_for_inspector) return;

    const competencyScores = quiz.answer_on_question_for_inspector.filter(answer => answer.category_name === "Оценка по компетенциям");

    const totalScoreCompetency = competencyScores.reduce((sum, answer) => sum + parseFloat(answer.answer), 0);
    const averageScoreCompetency = totalScoreCompetency / competencyScores.length;
    const personalEffectivenessScore = quiz.answer_on_question_for_inspector.find(answer => answer.category_name === "Оценка личной результативности" && answer.question_name === "Личная результативность");

    const updatedFinalScoreForInspector = quiz.answer_on_question_for_inspector.map(answer => {
        if (answer.category_name === "Итоговая оценка") {
            if (answer.question_name === "Оценка по компетенциям") {
                return { ...answer, answer: getGradeInterval(averageScoreCompetency.toFixed(1)) };
            } else if (answer.question_name === "Личная результативность") {
                return { ...answer, answer: personalEffectivenessScore.answer };
            }
        }
        return answer;
    });

    set((state) => ({
        quiz: {
            ...state.quiz,
            answer_on_question_for_inspector: updatedFinalScoreForInspector,
        },
    }));
};






useQuizStore.subscribe(
    (state) => state.quiz,
    (quiz) => {
        const currentProfile = getCurrentProfile();
        if (currentProfile) {
            useQuizStore.setState((state) => ({
                ...state,
                isYours: currentProfile.id === quiz.staff_id,
                isInspector: currentProfile.id === quiz.inspector_id,
                isCalibrator: currentProfile.id === quiz.calibration_id,
            }));
        }

        if (quiz && quiz.answer_on_question_for_staff && quiz.answer_on_question_for_inspector) {
            useQuizStore.getState().mergeInspectorAnswers(
                quiz.answer_on_question_for_staff,
                quiz.answer_on_question_for_inspector
            );
        }

        if (quiz && quiz.answer_on_question_for_calibration && quiz.answer_on_question_for_inspector) {
            useQuizStore.getState().mergeCalibrationAnswers(
                quiz.answer_on_question_for_inspector,
                quiz.answer_on_question_for_calibration
            );
        }

        if (quiz) {
            useQuizStore.getState().sortStaffQuiz();
            useQuizStore.getState().sortInspectorQuiz();
            useQuizStore.getState().sortCalibrateQuiz();
            useQuizStore.getState().getAnalyticsInfo();
        }
    }
);


const getCurrentProfile = () => {
    const currentProfile = JSON.parse(getCookie('currentProfile'));
    return currentProfile;
};

const getGradeInterval = (score) => {
    if (score < 0) {
        return null;
    } else if (score <= 0.5) {
        return "0.0";
    } else if (score > 0.5 && score <= 0.75) {
        return "0.0";
    } else if (score > 0.75 && score <= 2) {
        return "1.5";
    } else if (score > 2 && score <= 2.23) {
        return "1.5";
    } else if (score > 2.23 && score <= 3) {
        return "3.0";
    } else if (score > 3 && score < 3.5) {
        return "3.0";
    } else if (score >= 3.5 && score < 4.5) {
        return "4.0";
    } else if (score >= 4.5 && score < 4.6) {
        return "5.0";
    } else if (score >= 4.6) {
        return "5.0";
    }
};


export default useQuizStore;
