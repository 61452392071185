import { renderAnswer } from "./UtilityFunctions";
import TextareaAutosize from 'react-textarea-autosize';
const NotEditableQuestionItem = ({ item, isCalibrate }) => {
    return (
        <div className="question-item__div-ne" key={Math.random()}>
            <div className="question-item__header">
                <p className="question-item__title">{item.category_name}</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                {item.answers.map((answer, index) => {
                    return (
                        <>
                            <div
                                key={answer.staff.id_answer}
                                className="question-item__row-ne"
                            >
                                <p>{answer.staff && answer.staff.question_name}</p>
                                <div className="question-item__merged-row">

                                    <div className="question-item__single-score">
                                        <p>Оценка сотрудника</p>
                                        <div className={`${answer?.staff?.answer ? 'staff-answer' : 'empty-answer'}`} title='Рассчитывается автоматически'>
                                            {answer?.staff?.answer ? renderAnswer(answer?.staff?.answer) : '?'}
                                        </div>
                                    </div>


                                    <div className="question-item__single-score">
                                        <p>Оценка руководителя</p>
                                        <div className={`${answer?.inspector?.answer ? 'staff-answer' : 'empty-answer'}`} title='Рассчитывается автоматически'>
                                            {answer?.inspector?.answer ? renderAnswer(answer?.inspector?.answer) : '?'}
                                        </div>
                                    </div>

                                </div>
                            </div >
                        </>
                    );
                })}
            </div >
        </div >
    )
}

export default NotEditableQuestionItem;