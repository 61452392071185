import React, { useState } from 'react';
import './AnswerItem.css';
import useQuizStore from '../../../utils/stores/useQuizStore';
import TextareaAutosize from 'react-textarea-autosize';
import DescriptionPopup from './DescriptionPopup';

const AnswerItem = ({ item }) => {
    const { handleUpdateComment, handleUpdateAnswer, quiz, isYours, isInspector } = useQuizStore();

    const [popupsState, setPopupsState] = useState([]);

    const {
        category_name: categoryName,
        answers
    } = item;

    const handleCommentChange = (questionId, e) => {
        handleUpdateComment(questionId, e.target.value, 'inspector');
    };

    const handleAnswerChange = (questionId, value) => {
        handleUpdateAnswer(questionId, value, 'inspector');
    };

    const handlePopupStateChange = (index) => {
        setPopupsState(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const answerValues = ['5.0', '4.0', '3.0', '1.5', '0.0'];
    const answerLabel = {
        '5.0': 'A',
        '4.0': 'B',
        '3.0': 'C',
        '1.5': 'D',
        '0.0': 'E'
    };

    const splitDescription = (description) => {
        const parts = description.split(/\([A-Z]\)\s*(?=\S)/).filter(part => part.trim() !== '');
        return parts;
    };

    return (
        <>
            <div className="answer-item">
                <div className="question-header">
                    <p>{categoryName}</p>
                </div>
                <div className="question-content">
                    {answers?.map((answer, index) => (
                        <div className='question-row' key={index}>
                            <div className="question-section">
                                <div className="question-title">
                                    <div className='question-info'>
                                        <span>{answer.staff.question_name}</span>
                                        <span className='question-desciption__button' onClick={() => handlePopupStateChange(index, true)}>?</span>
                                    </div>
                                    <div className="question-answer">
                                        <span id='inspqz-span'>Оценка сотрудника</span>
                                        <div className={answer.staff.answer ? 'staff-answer' : 'empty-answer'}>{answer.staff.answer ? answerLabel[answer.staff.answer] : '?'}</div>
                                    </div>
                                </div>
                                {answer.staff.comment ?
                                    <div className="question-comment">
                                        <p style={{ color: '#666666' }}>—{quiz.full_name}</p>
                                        <p>{answer.staff.comment}</p>
                                    </div>
                                    :
                                    <div className="question-comment">
                                        <p>Сотрудник не оставил комментарий</p>
                                    </div>
                                }
                            </div>
                            {answer.staff.question_description && (
                                <DescriptionPopup
                                    isPopupOpened={popupsState[index]}
                                    setPopupOpened={handlePopupStateChange}
                                    index={index}
                                    description={splitDescription(answer.staff.question_description)}
                                />
                            )}
                            {answer.inspector && (
                                <>
                                    <div className="inspector-section">
                                        <div className="inspector-title">
                                            <span>Оценка руководителя</span>
                                        </div>
                                        <div className="inspector-answer">
                                            {['A', 'B', 'C', 'D', 'E'].map((label, idx) => (
                                                <button
                                                    onClick={() => handleAnswerChange(answer.inspector.id_answer, answerValues[idx])}
                                                    key={label}
                                                    type='button'
                                                    className={`inspector-button ${parseFloat(answer.inspector.answer) === parseFloat(answerValues[idx]) ? 'selected' : ''}`}
                                                    disabled={!isInspector}
                                                >
                                                    {label}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                    <TextareaAutosize
                                        type="text"
                                        maxRows={15}
                                        className='inspector__input'
                                        placeholder='Введите комментарий...'
                                        value={answer.inspector.comment || ''}
                                        onChange={(e) => handleCommentChange(answer.inspector.question_id, e)}
                                    />
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AnswerItem;
