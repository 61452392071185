import { useParams } from "react-router-dom";
import api from "../../utils/Api";
import { useEffect, useState } from "react";
import LoadingComponent from "../../utils/LoadingComponent";
import AnswerItem from "./AnswerItem/AnswerItem";
import useQuizStore from "../../utils/stores/useQuizStore";
import SummaryAnswerItem from "./AnswerItem/SummaryAnswerItem";
import NotEditableQuestionItem from "../QuizModule/utils/NotEditableQuestionItem";
import QuestionItem from "../../components/QuizBody/QuestionItem";
import TextareaAutosize from 'react-textarea-autosize';
import { renderAnswer } from "../QuizModule/utils/UtilityFunctions";
export default function InspectorQuizModule() {
    const { quizId } = useParams();
    const { mergedInspectorQuiz, quiz, isYours, isInspector, groupedInspectorQuiz, handleUpdateFullQuizComment, groupedCalibrateQuiz, currentPeriod } = useQuizStore();

    // Сюда попадают анкеты для оценки руководителем и уже заполненные сотрудником/руком
    // Изначально нужно разделить isYours и isInspector
    // Потом разделить !isYours и !isInspector (например если смотрит HR, или любой другой человек не имеющий отношения к анкете)
    // Показываем сводку об оценках если смотрит сотрудник или если период рука закончился isYours || currentPeriod !== 'inspector' || (isInspector && !isYours)
    // 1. Смотрит сотрудник после заполнения своей оценки (в любой промежуток времени, главный фактор что статус != Создана)
    // 2. Смотрит руководитель в период калибровки
    // 3. 
    // <SummaryAnswerItem key={index} item={item} /> - без доступа к редактированию\
    // <AnswerItem key={index} item={item} /> - с доступом к редактированию
    // Редактировать на этом этапе может ТОЛЬКО руководитель
    // quiz.status_doc_quiz_name
    // currentPeriod
    // 

    return (

        <>
            {mergedInspectorQuiz
                ?.filter(item => !(item.category_name === 'Оценка по компетенциям' || item.category_name === 'Итоговая оценка'))
                ?.map((item, index) => {
                    if (
                        isInspector
                        &&
                        (currentPeriod === 'inspector' || quiz.status_doc_quiz_name === 'Оценен сотрудником' || quiz.status_doc_quiz_name === 'Оценен руководителем')
                        && quiz.status_doc_quiz_name !== 'Завершен'
                        && currentPeriod !== 'calibration'
                    ) {
                        return <AnswerItem key={index} item={item} />
                    } else {
                        return <SummaryAnswerItem key={index} item={item} />
                    }
                })}
            <div className="answer-item">
                <div className="question-header">
                    <p>Итоговый комментарий сотруднику</p>
                </div>
                {quiz?.status_doc_quiz_name === 'Оценен руководителем' || quiz?.status_doc_quiz_name === 'Завершен' ? (
                    quiz?.comment?.length > 0 ? (
                        <div className="question-comment">
                            <p style={{ color: '#666666' }}>— {quiz.full_name_inspector}</p>
                            <p>{quiz.comment}</p>
                        </div>
                    ) : (
                        <div className="question-comment">
                            <p>Руководитель не оставил комментарий</p>
                        </div>
                    )
                ) : (
                    null
                )}
                {isInspector && (quiz?.status_doc_quiz_name === 'Оценен сотрудником' || quiz?.status_doc_quiz_name === 'Создана') && (
                    <TextareaAutosize
                        type="text"
                        maxRows={5}
                        className="inspector__input"
                        placeholder="Введите комментарий..."
                        value={quiz?.comment?.length > 0 ? quiz.comment : ''}
                        style={{ color: '#000' }}
                        disabled={!isInspector || quiz.status_doc_quiz_name === 'Оценен руководителем' || isYours}
                        onChange={(e) => handleUpdateFullQuizComment(e.target.value)}
                    />
                )}
            </div>

            {quiz?.status_finishing
                ?
                <>
                    {mergedInspectorQuiz
                        ?.filter(item => item.category_name === 'Оценка по компетенциям')
                        ?.map((item, index) => {
                            return <NotEditableQuestionItem key={index} item={item} />;
                        })}
                    {mergedInspectorQuiz
                        ?.filter(item => item.category_name === 'Итоговая оценка')
                        ?.map((item, index) => {
                            return <NotEditableQuestionItem key={index} item={item} isCalibrate={true} />;
                        })}
                </>
                :
                <>
                    {mergedInspectorQuiz
                        ?.filter(item => item.category_name === 'Оценка по компетенциям' || item.category_name === 'Итоговая оценка')
                        ?.map((item, index) => {
                            return <NotEditableQuestionItem key={index} item={item} />;
                        })}
                </>
            }

        </>
    );
}
