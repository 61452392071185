import './QuickActions.css';
import history from '../../utils/images/history.svg'
import quick_menu from '../../utils/images/quick-menu.svg';
import quick_quiz from '../../utils/images/quick-quiz.svg';
import { useNavigate } from 'react-router-dom';
function QuickActions({ display, isLoading }) {
    const navigate = useNavigate();

    if (isLoading) return null;

    return (
        <>
            {display ?
                <div className="quick-actions__div">
                    <button className="quick-actions__button" onClick={() => navigate('/methodology')}>
                        Методология оценки
                    </button>
                </div>
                : null}
        </>
    );

}

export default QuickActions;