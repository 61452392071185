import React, { useState } from "react";
import InputMask from "react-input-mask";
import { motion } from 'framer-motion';
import phoneIcon from '../../../utils/images/phone_icon.svg';
import auth from "../../../utils/Auth";
import { useNavigate } from "react-router-dom";
import api from "../../../utils/Api";
import arrow_left from '../../../utils/images/arrow_left.svg';
import useUserStore from "../../../utils/stores/useUserStore";
import Cookies from 'js-cookie';
import { setCookie, setToken } from "../../../utils/cookieHandlers";
function PhoneLogin({ setLoadingError }) {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [code, setCode] = useState('');
    const [loginStep, setLoginStep] = useState(0);
    const [buttonState, setButtonState] = useState('Отправить код');
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    const { fetchProfile, setUserProfile, setIsLoggedIn } = useUserStore();

    const sentCode = async () => {
        if (phoneNumber) {
            setLoginError('');
            let errorOccurred = false;
            try {
                setButtonState('Отправка кода...');
                const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
                const res = await auth.handleGetCode(cleanedPhoneNumber);
            } catch (error) {
                if (error.message) {
                    console.log(error.message);
                    setLoadingError(true);
                }
                if (error.responseBody && error.responseBody.non_field_errors && error.responseBody.non_field_errors[0]) {
                    setLoginError(error.responseBody.non_field_errors[0]);
                } else {
                    setLoginError('Произошла ошибка при отправке кода');
                }
                errorOccurred = true;
                setButtonState('Отправить код');
            } finally {
                if (!errorOccurred) {
                    setLoginStep(1);
                    setButtonState('Подтвердить');
                }
            }
        }
    }

    const login = async () => {
        try {
            setButtonState('Вход...');
            const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
            const cleanedCode = code.replace(/\D/g, '');
            const authToken = await auth.handleLogin({ username: cleanedPhoneNumber, password: cleanedCode });
            setToken(authToken.auth_token)
            const userProfile = await fetchAndSetUserData(authToken.auth_token);
            setUserProfile(userProfile);
            navigate('/profile');
            setIsLoggedIn(true);
        } catch (error) {
            if (error.message) {
                console.log(error.message);
                setLoadingError(true);
            }
            setLoginError(error.responseBody?.non_field_errors[0]);
        } finally {
            setButtonState('Войти');
        }
    }

    const fetchAndSetUserData = async (authToken) => {
        const user = await api.fetchUser(authToken);
        setCookie('currentUser', JSON.stringify(user));
        const profile = await api.fetchProfile(authToken, user.id);
        const userProfile = profile.results[0];
        setCookie('currentProfile', JSON.stringify(userProfile));
        await fetchProfile();
        return userProfile;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        switch (loginStep) {
            case 0:
                sentCode();
                break;
            case 1:
                login();
                break;
            default:
                break;
        }
    };

    return (
        <>
            <form className="auth-form" onSubmit={handleSubmit}>
                <motion.div className={`auth-form__row`} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                    {loginStep === 1 && <img className="auth-form__mobile-arrow" src={arrow_left} alt="" onClick={() => setLoginStep(0)}></img>}
                    {loginStep === 0 &&
                        <>
                            <p className='auth-form__title'>Телефон</p>
                            <InputMask
                                key='mobile-auth-input'
                                mask='+7 999 999 9999'
                                maskChar=''
                                id='phone'
                                className="auth-form__input"
                                placeholder="+7 "
                                type="tel"
                                required
                                guide='false'
                                autoFocus={true}
                                value={phoneNumber}
                                style={{ textAlign: 'center' }}
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                }}
                            />
                        </>
                    }
                    {loginStep === 1 &&
                        <>
                            <div className="auth-form__mobile-div">
                                <p style={{ fontWeight: 'bold', fontSize: '18px' }}>Введите код из Telegram Бота</p>
                                <p style={{ fontWeight: '100' }}>Мы отправили его на номер</p>
                                <p style={{ fontWeight: '100' }}>{phoneNumber}</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: '100%' }}>
                                <p>Код из сообщения</p>
                                <InputMask
                                    key='mobile-auth-input-code'
                                    mask='999999'
                                    maskChar=''
                                    id='code'
                                    className="auth-form__input"
                                    required
                                    guide='false'
                                    value={code}
                                    autoFocus={true}
                                    onChange={(e) => {
                                        setCode(e.target.value);
                                    }}
                                />
                            </div>
                        </>
                    }
                    {loginError && <p className="auth-form__error">{loginError}</p>}
                    <div style={{ width: '100%' }}>
                        {loginStep === 0 && <p className="auth-form__description">
                            Учтите, что для авторизации в сервисе, вы должны использовать <span>рабочий номер телефона</span>. Код для входа придет от <span>Telegram бота PridexBot</span>.</p>
                        }
                        <button style={{ width: '100%' }} className="auth-form__button">
                            {buttonState}
                        </button>
                    </div>
                </motion.div>
            </form>
            <span style={{ marginTop: '20px' }}>Возникли трудности?</span>
            <a href="https://support.pridex.ru" target="_blank" style={{ marginTop: '5px', fontSize: '14px' }}>Обратиться в поддержку</a>
        </>
    )
}

export default PhoneLogin;
