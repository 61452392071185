import React from 'react';
import DualDistributionChart from "./components/DistributionChart/DistributionChart";
import './ManagerAnalyticsModule.css'; // Создайте этот файл для стилей
import useQuizStore from '../../utils/stores/useQuizStore';
import { Tooltip } from 'react-tooltip';
function ManagerAnalyticsModule() {
    const { quizAnalytics } = useQuizStore();

    const idealData = {
        "A_percentage": 10,  // 10% оценок A
        "B_percentage": 10,  // 20% оценок B
        "C_percentage": 74,  // 40% оценок C
        "D_percentage": 5,  // 20% оценок D
        "E_percentage": 1   // 10% оценок E
    };

    return (
        <div className="manager-analytics">
            <div id='manager-analytic__description'>?</div>
            <Tooltip className="manager-analytic__tooltip" anchorSelect='#manager-analytic__description'>
                Графики отображают идеальное и реальное распределение оценок для вашего отдела. Функционал находится на тестировании и предоставлен для ознакомления. Данные обновляются после каждого сохранения анкет.
            </Tooltip>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <p>Личная результативность</p>
                <p>Оценка по компетенциям</p>
            </div>
            <div className="manager-analytics__charts">
                <DualDistributionChart
                    realData={quizAnalytics}
                    idealData={idealData}
                />
            </div>
            <div className="manager-analytics__info">
                <p className='manager-analytics__disclaimer'>Аналитика предоставляется исключительно для ознакомления и находится в стадии тестирования.</p>
            </div>
        </div>
    );
}

export default ManagerAnalyticsModule;